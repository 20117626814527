import React from 'react';

export const EyeOffIcon = (props) => (
    <svg {...props} viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M26.5998 10.6L22.1998 6.2L22.0998 6.1L26.0998 2.1L23.8998 0L17.3998 6.5L14.7998 9.1L1.8998 22L3.9998 24.1L8.2998 19.8C9.9998 20.8 11.9998 21.3 13.8998 21.3C16.7998 21.3 19.7998 20.2 22.0998 17.9L26.4998 13.5C27.2998 12.7 27.2998 11.4 26.5998 10.6ZM19.9998 15.8C18.3998 17.4 16.1998 18.3 13.9998 18.3C12.7998 18.3 11.6998 18.1 10.5998 17.6L13.1998 15C13.3998 15.1 13.6998 15.1 13.9998 15.1C15.6998 15.1 16.9998 13.8 16.9998 12.1C16.9998 11.8 16.9998 11.6 16.8998 11.3L19.8998 8.3L19.9998 8.4L23.6998 12.1L19.9998 15.8ZM1.3998 13.5C0.599805 12.7 0.599805 11.5 1.3998 10.7L5.7998 6.2C8.0998 3.9 10.9998 2.8 13.9998 2.8C14.8998 2.8 15.6998 2.9 16.5998 3.1L13.7998 5.8C11.5998 5.8 9.4998 6.7 7.8998 8.3L4.1998 12L5.8998 13.7L3.7998 15.9L1.3998 13.5Z"
            fill="#1C1E21"
        />
    </svg>
);

export default EyeOffIcon;
