import { axiosCloudConfig } from '../../../../../../../../api/config';
import { API_CLOUD_EZLO_REQUEST_URL } from '../../../../../../../../constants/URLs';
import { buildIntegrationsGetConfigRequestBody } from '../../../../../../EzloParjectsSettings/utils';
import { INDEX_OF_UUID_INDEX } from '../paas-constants';
import {
    getIntegrationsPreviewRequestParams,
    buildAuthUrlConfigRequestParams,
    getEnumListMethodRequestBody,
    getEnumListMethodRequestBodyForTrigger,
    getPaasAuthEnrollRequestParams,
} from './paas-request-params-generators';
import { deleteAbstractRequestParams, getAuthUrl } from './paas-responce-extractors';

export const apiGetIntegrationPreview = async (integrationUuid) => {
    const cloudUrl = API_CLOUD_EZLO_REQUEST_URL();
    const integrationPreviewRequestParams = getIntegrationsPreviewRequestParams(integrationUuid);

    const integrationPreviewResponse = await fetch(cloudUrl, integrationPreviewRequestParams);
    const integrationPreview = await integrationPreviewResponse.json();
    const { status } = integrationPreview;

    if (status === 0) {
        return { status };
    }

    const { data } = integrationPreview;

    return { status, integrationPreview: data };
};

const apiGetIntegrationsConfig = async (parjectUuid) => {
    const params = {
        uuids: [parjectUuid],
    };
    const integrationsConfigParams = buildIntegrationsGetConfigRequestBody(params);

    const integrationsConfigResponse = await fetch(API_CLOUD_EZLO_REQUEST_URL(), {
        method: 'post',
        ...axiosCloudConfig(),
        body: JSON.stringify(integrationsConfigParams),
    });

    const { data } = await integrationsConfigResponse?.json();

    return data?.integrations?.[parjectUuid]?.config_entity?.index;
};

const findUuidIndex = (obj = {}, value) => {
    const foundUuidIndex = Object.entries(obj)?.find(([, val]) => val?.abstract?.uuid === value);

    return foundUuidIndex?.[INDEX_OF_UUID_INDEX];
};

const getUuidIndex = async (parjectUuid, abstractUuid) => {
    const result = await apiGetIntegrationsConfig(parjectUuid);

    return findUuidIndex(result, abstractUuid);
};

export const apiDeleteAbstractByUuid = async (abstractUuid, parjectUuid) => {
    const uuidIndex = await getUuidIndex(parjectUuid, abstractUuid);
    const deleteAbstractParams = deleteAbstractRequestParams(parjectUuid, uuidIndex);
    await fetch(API_CLOUD_EZLO_REQUEST_URL(), {
        method: 'post',
        ...axiosCloudConfig(),
        body: JSON.stringify(deleteAbstractParams),
    });
};

export const apiEnrollByAuthUrl = async (PAASAuthUuid, authType, authParjectId) => {
    const authUrlConfigRequestParams = buildAuthUrlConfigRequestParams(PAASAuthUuid, authType, authParjectId);

    const authUrlPromise = await fetch(API_CLOUD_EZLO_REQUEST_URL(), authUrlConfigRequestParams);
    const { data } = await authUrlPromise.json();
    const authUrl = getAuthUrl(data);

    return authUrl;
};

export const apiGetEnumList = async (accountUuid, enumListMethod) => {
    const enumListRequestParams = getEnumListMethodRequestBody(accountUuid, enumListMethod);

    const enumResponse = await fetch(API_CLOUD_EZLO_REQUEST_URL(), {
        method: 'post',
        ...axiosCloudConfig(),
        body: JSON.stringify(enumListRequestParams),
    });
    const { data } = await enumResponse?.json();

    return data?.body?.data?.list ?? data?.body?.data?.enum;
};

export const apiGetEnumListForTrigger = async (accountUuid, method) => {
    const enumListRequestParams = getEnumListMethodRequestBodyForTrigger(accountUuid, method);

    const enumResponseForTrigger = await fetch(API_CLOUD_EZLO_REQUEST_URL(), {
        method: 'post',
        ...axiosCloudConfig(),
        body: JSON.stringify(enumListRequestParams),
    });
    const { data } = await enumResponseForTrigger.json();

    return data;
};

export const apiSubscribeToCallBackForTrigger = async (subscription) => {
    const subscriptionResponseForCallBackTrigger = await fetch(API_CLOUD_EZLO_REQUEST_URL(), {
        method: 'post',
        ...axiosCloudConfig(),
        body: JSON.stringify(subscription),
    });
    const data = await subscriptionResponseForCallBackTrigger.json();

    return data;
};

export const apiEnrollWithoutAuth = async (parjectUuid) => {
    const noAuthRequestParams = getPaasAuthEnrollRequestParams(parjectUuid);

    await fetch(API_CLOUD_EZLO_REQUEST_URL(), noAuthRequestParams);
};
