export const CloudMeshbotActionTypes = {
    SET_CLOUD_MESHBOT_NAME: 'SET_CLOUD_MESHBOT_NAME',
    SET_CLOUD_MESHBOT_NAME_BEFORE_EDITING: 'SET_CLOUD_MESHBOT_NAME_BEFORE_EDITING',
    CLEAR_CLOUD_CURRENT_SCENE: 'CLEAR_CLOUD_CURRENT_SCENE',

    SET_CLOUD_TRIGGERS_FOR_EDIT: 'SET_CLOUD_TRIGGERS_FOR_EDIT',
    SET_CLOUD_ACTIONS_FOR_EDIT: 'SET_CLOUD_ACTIONS_FOR_EDIT',
    ADD_CLOUD_TRIGGER: 'ADD_CLOUD_TRIGGER',
    ADD_CLOUD_ACTIONS: 'ADD_CLOUD_ACTIONS',
    DELETE_CLOUD_TRIGGER: 'DELETE_CLOUD_TRIGGER',
    DELETE_CLOUD_ACTIONS: 'DELETE_CLOUD_ACTIONS',
    UPDATE_CLOUD_TRIGGER: 'UPDATE_CLOUD_TRIGGER',
    UPDATE_CLOUD_TRIGGER_NUCAL: 'UPDATE_CLOUD_TRIGGER_NUCAL',
    UPDATE_CLOUD_NUCAL_SUBSCRIPTION_IN_KVS: 'UPDATE_CLOUD_NUCAL_SUBSCRIPTION_IN_KVS',
    UPDATE_CLOUD_ACTIONS: 'UPDATE_CLOUD_ACTIONS',
    UPDATE_SELECTED_PARENT_OPERATOR: 'UPDATE_SELECTED_PARENT_OPERATOR',
    UPDATE_CLOUD_TRIGGER_BLOCK: 'UPDATE_CLOUD_TRIGGER_BLOCK',
    UPDATE_DATE_TRIGGER_CLOUD: 'UPDATE_DATE_TRIGGER_CLOUD',
    CLEAR_TRIGGER: 'CLEAR_TRIGGER',
    UPDATE_CURRENT_MESH_SCENE: 'UPDATE_CURRENT_MESH_SCENE',
    DUPLICATE_CURRENT_MESHBOT_SCENE: 'DUPLICATE_CURRENT_MESHBOT_SCENE',
    DUPLICATE_CURRENT_MESHBOT_SCENE_NAME: 'DUPLICATE_CURRENT_MESHBOT_SCENE_NAME',
    UPDATE_CLOUD_ACTION_DRAG_AND_DROP: 'UPDATE_CLOUD_ACTION_DRAG_AND_DROP',
    UPDATE_CLOUD_META: 'UPDATE_CLOUD_META',
    UPDATE_CLOUD_REQUIRED_ACTION: 'UPDATE_CLOUD_REQUIRED_ACTION',
    UPDATE_CLOUD_REQUIRED_TRIGGER: 'UPDATE_CLOUD_REQUIRED_TRIGGER',
    UPDATE_CLOUD_VARIABLE_TRIGGER: 'UPDATE_CLOUD_VARIABLE_TRIGGER',
    UPDATE_CLOUD_VARIABLE_TRIGGER_BLOCKS: 'UPDATE_CLOUD_VARIABLE_TRIGGER_BLOCKS',
    CLOUD_SCENE_SET: 'CLOUD_SCENE_SET',
    CLOUD_SCENE_SUCCESSFULLY_FINISHED: 'CLOUD_SCENE_SUCCESSFULLY_FINISHED',
    CLOUD_SCENE_SET_CAUGHT: 'CLOUD_SCENE_SET_CAUGHT',
    SET_CLOUD_MESHBOT_LABELS: 'SET_CLOUD_MESHBOT_LABELS',
};
