import hash from '../../../constants/uniqueHash';

export const virtualItemMapTypes = {
    FUNCTION_X_STATE: 'function_x_state',
    FUNCTION_X_COMMAND: 'function_x_command',
};

export const mapTypes = {
    STANDARD: 'STANDARD',
    ADVANCED: 'ADVANCED',
};

export const virtualItemKeys = {
    VIRTUAL_TRIGGERS: 'virtualTriggers',
    VIRTUAL_ACTIONS: 'virtualActions',
};

export const VARIABLES_DEFINITION = 'variables';
export const COMMANDS_DEFINITION = 'commands';

export const INTEGRATION_SOURCE = 'integration';
export const INTEGRATION_UUID = 'integration_uuid';
export const VIRTUAL_DEVICE_SOURCE = 'virtual_device';

export const VIRTUAL_DEVICE_COMMAND = 'command';
export const VIRTUAL_DEVICE_CODE = 'code';

export const VIRTUAL_DEVICE_CALL = 'call';
export const SCHEMA_REF_KEY = '$ref';

export const REGEXP_TEMPLATE_METHOD_NAME = /[^\w]/g;

export const NOT_ALLOWED_CUSTOM_CAPABILITY = 'custom_';

export const capabilityNeedToReplace = {
    EXISTING_VALUE: 'api_command',
    EXPECTING_VALUE: 'api',
};

export const uniqKeyForVirtualItem = {
    [virtualItemKeys.VIRTUAL_TRIGGERS]: 'variable',
    [virtualItemKeys.VIRTUAL_ACTIONS]: 'command',
};

export const initialVirtualDevice = {
    name: '',
    virtualTriggers: [],
    virtualActions: [],
};

export const getVirtualItemTemplate = (mapType) => ({
    uuid: hash(),
    capability: '',
    map_type: mapType,
    map_conf: {
        code: '',
        language: 'javascript',
    },
    mapType: mapTypes.ADVANCED,
});

export const getJsForState = (capabilityName, variable, type) => {
    return `
        var state = [];

        state = [
            ['${capabilityName}', '${variable}', raw_json.${variable}, '${type}']
        ];

        state;
    `;
};

export const getJsForCommand = (capabilityName, type) => {
    return `
        var output = [];

        output = {
            argument: [
              ['${capabilityName}', raw_json.parameters.${capabilityName}, '${type}']
            ]
        };

        output;
    `;
};

export const formJsArgumentForCommandIntegration = (method, fields) => {
    const methodName = method.replace(REGEXP_TEMPLATE_METHOD_NAME, '');

    return `
        var output = [];

        output = {
            argument: [
                ["name", ${JSON.stringify(methodName)}, "string"],
                ["params", ${JSON.stringify(fields)}, "string" ]
            ]
        };

         output;
    `;
};
