import React from 'react';

export const EyeIcon = (props) => (
    <svg {...props} viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14 3.80005C16.3 3.80005 18.4 4.70005 20 6.30005L23.7 10L20 13.7C18.4 15.3 16.2 16.2 14 16.2C11.8 16.2 9.6 15.3 8 13.7L4.3 10L8 6.30005C9.6 4.70005 11.7 3.80005 14 3.80005ZM14 0.800049C11 0.800049 8.1 2.00005 5.8 4.20005L1.4 8.60005C0.600003 9.40005 0.600003 10.6 1.4 11.4L5.8 15.9C8.1 18.2 11 19.3 14 19.3C17 19.3 19.9 18.2 22.2 15.9L26.6 11.5C27.4 10.7 27.4 9.50005 26.6 8.70005L22.2 4.30005C19.9 2.00005 16.9 0.800049 14 0.800049ZM14 7.10005C12.3 7.10005 11 8.40005 11 10.1C11 11.8 12.3 13.1 14 13.1C15.7 13.1 17 11.8 17 10.1C17 8.40005 15.6 7.10005 14 7.10005Z"
            fill="#1C1E21"
        />
    </svg>
);

export default EyeIcon;
