export default {
    UPDATE_SYSTEM_HISTORY_TABLE: {
        PENDING: 'UPDATE_SYSTEM_HISTORY_TABLE/pending',
        SUCCESS: 'UPDATE_SYSTEM_HISTORY_TABLE/success',
        REJECT: 'UPDATE_SYSTEM_HISTORY_TABLE/reject',
    },

    CANNOT_GET_HISTORY_LIST: 'Cannot get history list',
    SET_HISTORY_SEARCH_BY_DATE_BEFORE: 'SET_HISTORY_SEARCH_BY_DATE_BEFORE',
    SET_HISTORY_SEARCH_BY_DATE_AFTER: 'SET_HISTORY_SEARCH_BY_DATE_AFTER',
    SET_HISTORY_SEARCH_BY_EVENT_TYPE: 'SET_HISTORY_SEARCH_BY_EVENT_TYPE',
    SET_HISTORY_SEARCH_BY_DESCRIPTION: 'SET_HISTORY_SEARCH_BY_DESCRIPTION',
    SET_IS_VALID_DATE: 'SET_IS_VALID_DATE',
};
