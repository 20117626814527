export const GET_EZLOS = 'GET_EZLOS';
export const REQUEST_EZLOS = 'REQUEST_EZLOS';
export const RECEIVE_EZLOS = 'RECEIVE_EZLOS';

export const LOGIN = 'LOGIN';
export const SET_INFO_OF_LOGGED_USER = 'SET_INFO_OF_LOGGED_USER';
export const SET_IS_CUSTOMIZATION_PROGRESSING = 'SET_IS_CUSTOMIZATION_PROGRESSING';
export const ACCOUNT_LOGGED = 'ACCOUNT_LOGGED';
export const LOGIN_TYPES = {
    WITH_CREDENTIALS: 'WITH_CREDENTIALS',
    WITH_TOKEN: 'WITH_TOKEN',
    WITH_ITARIAN_TOKEN: 'WITH_ITARIAN_TOKEN',
};
export const LOGIN_CREDENTIALS_ERROR = 'LOGIN_CREDENTIALS_ERROR';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_RESET = 'LOGOUT_RESET';
export const START_RECONNECT = 'START_RECONNECT';
export const STOP_RECONNECT = 'STOP_RECONNECT';
export const START_CHECK_CONNECTION = 'START_CHECK_CONNECTION';
export const STOP_CHECK_CONNECTION = 'STOP_CHECK_CONNECTION';
export const START_POLLING = 'START_POLLING';
export const STOP_POLLING = 'STOP_POLLING';
export const SET_FORGOT_MSG_FOR_LOGIN_PAGE = 'SET_FORGOT_MSG_FOR_LOGIN_PAGE';
export const SET_DEVICE_PAGE_EDIT_MODE = 'SET_DEVICE_PAGE_EDIT_MODE';

export const CONNECT_EZLO = 'CONNECT_EZLO';
export const EZLO_CONNECTED = 'EZLO_CONNECTED';
export const DISCONNECT_EZLO = 'DISCONNECT_EZLO';
export const UPDATE_EZLO_DATA = 'UPDATE_EZLO_DATA';
export const UPDATE_EZLO_EXPRESSIONS = 'UPDATE_EZLO_EXPRESSIONS';

export const SET_ITEM_VALUE = 'SET_ITEM_VALUE';
export const SET_ITEM_ENUM = 'SET_ITEM_ENUM';
export const SET_ITEM_VALUE_SUFFIX = 'SET_ITEM_VALUE_SUFFIX';
export const SEND_ITEM_VALUE = 'SEND_ITEM_VALUE';
export const SET_ITEM_REACHABLE = 'SET_ITEM_REACHABLE';
export const SET_ITEM_DEFAULT = 'SET_ITEM_DEFAULT';
export const SET_GATEWAY_BUSY = 'SET_GATEWAY_BUSY';
export const SET_GATEWAY_DEVICE_MANAGE_STATE = 'SET_GATEWAY_DEVICE_MANAGE_STATE';
export const UPDATE_GATEWAY = 'UPDATE_GATEWAY';
export const REMOVE_GATEWAY = 'REMOVE_GATEWAY';
export const ADD_GATEWAY = 'ADD_GATEWAY';

export const ADD_DEVICE = 'ADD_DEVICE';
export const REMOVE_DEVICE = 'REMOVE_DEVICE';
export const SET_DEVICE_REACHABLE = 'SET_DEVICE_REACHABLE';

export const ADD_ITEM = 'ADD_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const REMOVE_ITEM = 'REMOVE_ITEM';
// export const HIDE_ITEM = 'HIDE_ITEM';
// export const UNHIDE_ITEM = 'UNHIDE_ITEM';
// export const CLEAR_ON_ITEMS = 'CLEAR_ON_ITEMS';
// export const STORE_ON_ITEM = 'STORE_ON_ITEM';
// export const UNSTORE_ON_ITEM = 'UNSTORE_ON_ITEM';
export const UPDATE_ITEM_ROOM = 'UPDATE_ITEM_ROOM';
export const UPDATE_SHOW_DEFAULT_ITEM = 'UPDATE_SHOW_DEFAULT_ITEM';
export const FAVORITE_ITEM_ADDED = 'FAVORITE_ITEM_ADDED';
export const FAVORITE_ITEM_REMOVED = 'FAVORITE_ITEM_REMOVED';

export const START_CALL_GATEWAY_METHOD = 'START_CALL_GATEWAY_METHOD';
export const END_CALL_GATEWAY_METHOD = 'END_CALL_GATEWAY_METHOD';
export const GATEWAY_METHOD_EVENT_DATA = 'GATEWAY_METHOD_EVENT_DATA';

export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const UPDATE_DEVICE_SETTINGS = 'UPDATE_DEVICE_SETTINGS';
export const UPDATE_DEVICE_DICTIONARY_SETTINGS = 'UPDATE_DEVICE_DICTIONARY_SETTINGS';
export const UPDATE_EZLO_PROPERTIES = 'UPDATE_EZLO_PROPERTIES';
export const UPDATE_EZLOS_PROPERTIES = 'UPDATE_EZLOS_PROPERTIES';
export const UPDATE_EZLO_NAME = 'UPDATE_EZLO_NAME';
export const UPDATE_EZLO_DESCRIPTION = 'UPDATE_EZLO_DESCRIPTION';
export const UPDATE_EZLO_IMAGE = 'UPDATE_EZLO_IMAGE';
export const UPDATE_EZLOS_IMAGE = 'UPDATE_EZLOS_IMAGE';
export const UPDATE_EZLO_LOCATION = 'UPDATE_EZLO_LOCATION';
export const UPDATE_EZLO_CONNECTION = 'UPDATE_EZLO_CONNECTION';
export const UPDATE_CURRENT_EZLO_CONNECTION = 'UPDATE_CURRENT_EZLO_CONNECTION';
export const UPDATE_EZLOS = 'UPDATE_EZLOS';
export const UPDATE_EZLO_STATE = 'UPDATE_EZLO_STATE';
export const UPDATE_EZLO_DISABLED = 'UPDATE_EZLO_DISABLED';
export const UPDATE_AUTO_FIRMWARE_UPDATE_STATE = 'UPDATE_AUTO_FIRMWARE_UPDATE_STATE';
export const UPDATE_TEMPERATURE_UNITS = 'UPDATE_TEMPERATURE_UNITS';

export const SEARCH_DEVICE_TEXT = 'SEARCH_DEVICE_TEXT';
export const REACHABLE_DEVICES = 'REACHABLE_DEVICES';
export const SORT_DEVICES = 'SORT_DEVICES';
export const SHOW_ALL_DEVICES = 'SHOW_ALL_DEVICES';
export const UPDATE_DEVICE_NAME = 'UPDATE_DEVICE_NAME';
export const UPDATE_DEVICE_ROOM = 'UPDATE_DEVICE_ROOM';

export const SEARCH_ITEM_TEXT = 'SEARCH_ITEM_TEXT';
export const UPDATE_SHOW_IN_TEMPLATE = 'UPDATE_SHOW_IN_TEMPLATE';
export const UPDATE_ITEM_LABEL = 'UPDATE_ITEM_LABEL';
export const UPDATE_ITEMS = 'UPDATE_ITEMS';

export const UPDATE_ROOMS = 'UPDATE_ROOMS';
export const UPDATE_ROOM_IMAGE = 'UPDATE_ROOM_IMAGE';
export const UPDATE_ROOM_NAME = 'UPDATE_ROOM_NAME';
export const DELETE_ROOM = 'DELETE_ROOM';

export const UPDATE_FIRMWARE_PROGRESS = 'UPDATE_FIRMWARE_PROGRESS';
export const UPDATE_FIRMWARE_VERSION = 'UPDATE_FIRMWARE_VERSION';

export const STORE_DEVICE_TEMPLATE = 'STORE_DEVICE_TEMPLATE';
export const REMOVE_DEVICE_TEMPLATE = 'REMOVE_DEVICE_TEMPLATE';
export const SET_DEVICE_TEMPLATE = 'SET_DEVICE_TEMPLATE';
export const UNSET_DEVICE_TEMPLATE = 'UNSET_DEVICE_TEMPLATE';

export const UPDATE_BACKUP_PROGRESS = 'UPDATE_BACKUP_PROGRESS';
export const UPDATE_RESTORE_PROGRESS = 'UPDATE_RESTORE_PROGRESS';

export const REMOVE_EZLO = 'REMOVE_EZLO';

export const RECEIVE_SHARED_USERS = 'RECEIVE_SHARED_USERS';
export const RECEIVE_USERS_TO_SHARE = 'RECEIVE_USERS_TO_SHARE';
export const ADD_SHARED_USER = 'ADD_SHARED_USER';
export const REMOVE_SHARED_USER = 'REMOVE_SHARED_USER';
export const UPDATE_SHARED_USER = 'UPDATE_SHARED_USER';
export const REMOVE_USER_FROM_ROOM = 'REMOVE_USER_FROM_ROOM';
export const ADD_USER_TO_ROOM = 'ADD_USER_TO_ROOM';

export const UPDATE_FAVORITES = 'UPDATE_FAVORITES';
export const FAVORITE_DEVICE_ADDED = 'FAVORITE_DEVICE_ADDED';
export const FAVORITE_DEVICE_REMOVED = 'FAVORITE_DEVICE_REMOVED';
export const MOVE_CHILD_DEVICES = 'MOVE_CHILD_DEVICES';

export const APP_LOADING = 'APP_LOADING';
export const APP_LOADED = 'APP_LOADED';

export const GRANT_ACCESS_ALLOW = 'GRANT_ACCESS_ALLOW';
export const GRANT_ACCESS_DISALLOW = 'GRANT_ACCESS_DISALLOW';

export const SET_WHATHAPPENED = 'SET_WHATHAPPENED';
export const SHOW_ABOUT_POPUP = 'SHOW_ABOUT_POPUP';
export const HIDE_ABOUT_POPUP = 'HIDE_ABOUT_POPUP';
export const SHOW_SELECT_UI_POPUP = 'SHOW_SELECT_UI_POPUP';
export const HIDE_SELECT_UI_POPUP = 'HIDE_SELECT_UI_POPUP';
export const SET_LINE_LOADING = 'SET_LINE_LOADING';

export const RESET_SELECTED_AUTOMATION = 'RESET_SELECTED_AUTOMATION';
export const UPDATE_SELECTED_AUTOMATION = 'UPDATE_SELECTED_AUTOMATION';
export const UPDATE_TIME_RULE_BLOCKS = 'UPDATE_TIME_RULE_BLOCKS';
export const UPDATE_DEVICE_ROOM_RULE_BLOCKS = 'UPDATE_DEVICE_ROOM_RULE_BLOCKS';
export const UPDATE_THEN_RULE_BLOCKS = 'UPDATE_THEN_RULE_BLOCKS';
export const UPDATE_RULES = 'UPDATE_RULES';
export const RESET_SELECTED_RULE = 'RESET_SELECTED_RULE';
export const UPDATE_SELECTED_RULE = 'UPDATE_SELECTED_RULE';
export const RESET_SELECTED_RULE_BLOCK = 'RESET_SELECTED_RULE_BLOCK';
export const UPDATE_SELECTED_RULE_BLOCK = 'UPDATE_SELECTED_RULE_BLOCK';
export const UPDATE_SELECTED_RULE_BLOCK_FIELD = 'UPDATE_SELECTED_RULE_BLOCK_FIELD';
export const ADD_RULE_BLOCK = 'ADD_RULE_BLOCK';
export const SET_RULE_BLOCK = 'SET_RULE_BLOCK';
export const UPDATE_RULE_BLOCK = 'UPDATE_RULE_BLOCK';
export const REMOVE_RULE_BLOCK = 'REMOVE_RULE_BLOCK';
export const CREATE_RULE = 'CREATE_RULE';
export const DELETE_RULE = 'DELETE_RULE';
export const ENABLE_RULE = 'ENABLE_RULE';
export const DISABLE_RULE = 'DISABLE_RULE';
export const ADD_RULE = 'ADD_RULE';
export const UPDATE_RULE = 'UPDATE_RULE';
export const REMOVE_RULE = 'REMOVE_RULE';

export const WIZARD_HUB_SET_EZLO_DEVICE_NAME = 'WIZARD_HUB_SET_EZLO_DEVICE_NAME';
export const WIZARD_HUB_SET_VALIDATION_STATE = 'WIZARD_HUB_SET_VALIDATION_STATE';
export const WIZARD_HUB_SERIAL_SELECTED_SERIAL_ID = 'WIZARD_HUB_SERIAL_SELECTED_SERIAL_ID';

/*
 * Action Types for managing modal dialogs
 * */
export const ADD_MODAL = 'ADD_MODAL';
export const REMOVE_MODAL = 'REMOVE_MODAL';
export const UPDATE_EZLO_LIST_SCENES = 'UPDATE_EZLO_LIST_SCENES';

export const UPDATE_MESH_LIST_SCENES = 'UPDATE_MESH_LIST_SCENES';
export const UPDATE_MESH_SCENES_ABSTRACTS = 'UPDATE_MESH_SCENES_ABSTRACTS';
export const UPDATE_MESH_SCENES_CAPABILITIES = 'UPDATE_MESH_SCENES_CAPABILITIES';
export const UPDATE_MESH_TRIGGER = 'UPDATE_MESH_TRIGGER';
export const UPDATE_MESH_ACTION = 'UPDATE_MESH_ACTION';

// Action Type for managing ezlo customization
export const UPDATE_EZLO_CUSTOMIZATION = 'UPDATE_EZLO_CUSTOMIZATION';
export const SET_DEFAULT_CUSTOMIZATION_TO_UPDATE_CUSTOMIZATION = 'SET_DEFAULT_CUSTOMIZATION_TO_UPDATE_CUSTOMIZATION';
export const INITIAL_CUSTOMIZATION = 'INITIAL_CUSTOMIZATION';
export const DEFAULT_CUSTOMIZATION = 'DEFAULT_CUSTOMIZATION';
export const DEFAULT_COLOR_THEME = 'DEFAULT_COLOR_THEME';
export const CUSTOMIZATION_API_STATUS = 'CUSTOMIZATION_API_STATUS';
export const PARTNER_DOMAIN_TO_PARTNER_UUID_API_STATUS = 'PARTNER_DOMAIN_TO_PARTNER_UUID_API_STATUS';
export const LOGO_ACTION = 'LOGO_ACTION';
export const PRIMARY_COLOR = 'PRIMARY_COLOR';
export const MENU_UI_ACTION = 'MENU_UI_ACTION';
export const EZLOGIC_PORTAL_COLOR_THEME = 'EZLOGIC_PORTAL_COLOR_THEME';
export const CURRENT_CUSTOMIZATION = 'CURRENT_CUSTOMIZATION';
export const MENU_UI_ACTION_PAYLOAD = 'MENU_UI_ACTION_PAYLOAD';
export const LISTING_UI_ACTION = 'LISTING_UI_ACTION';
export const MESHBOT_UI_ACTION = 'MESHBOT_UI_ACTION';
export const NODES_CONTROLLABLE_ACTION = 'NODES_CONTROLLABLE_ACTION';
export const KVS_PAYLOAD_ACTION = 'KVS_PAYLOAD_ACTION';
export const PRIMARY_COLOR_ACTION = 'PRIMARY_COLOR_ACTION';
export const IS_VERA_USER = 'IS_VERA_USER';
export const COLOR_THEME_ACTION = 'COLOR_THEME_ACTION';
export const PARTNER_PORTAL_DATA = 'PARTNER_PORTAL_DATA';
export const LOGO = 'LOGO';
export const DEFAULT_LOGO = 'DEFAULT_LOGO';

// Action Type for managing ezlo Support
export const UPDATE_SUPPORT_USERS = 'UPDATE_SUPPORT_USERS';
export const UPDATE_EZLO_SUPPORT_DATA = 'UPDATE_EZLO_SUPPORT_DATA';
export const UPDATE_TICKET_DATA_FROM_UI = 'UPDATE_TICKET_DATA_FROM_UI';
export const UPDATE_KVS_SUPPORT_TOKEN = 'UPDATE_KVS_SUPPORT_TOKEN';
export const UPDATE_LIST_OF_PRIORITIES = 'UPDATE_LIST_OF_PRIORITIES';
export const UPDATE_USER_UUID_FROM_KVS = 'UPDATE_USER_UUID_FROM_KVS';
export const UPDATE_LIST_OF_HELPTOPICS = 'UPDATE_LIST_OF_HELPTOPICS';
export const UPDATE_PARTNER_UUID = 'UPDATE_PARTNER_UUID';
export const CREATE_ASSOCIATION_PENDING = 'CREATE_ASSOCIATION/pending';
export const CREATE_ASSOCIATION_SUCCESS = 'CREATE_ASSOCIATION/success';
export const SET_ACTIVE_RUN_SCENES = 'SET_ACTIVE_RUN_SCENES';
export const SET_ACTIVE_LOCAL_RUN_SCENES = 'SET_ACTIVE_LOCAL_RUN_SCENES';
export const SET_ACTIVE_CLOUD_RUN_SCENES = 'SET_ACTIVE_CLOUD_RUN_SCENES';

export const SET_FINISHED_LOCAL_RUN_SCENES = 'SET_FINISHED_LOCAL_RUN_SCENES';
export const SET_FINISHED_CLOUD_RUN_SCENES = 'SET_FINISHED_CLOUD_RUN_SCENES';
export const SET_FAILED_RUN_SCENES = 'SET_FAILED_RUN_SCENES';
export const REMOVE_ACTIVE_LOCAL_RUN_SCENES = 'REMOVE_ACTIVE_LOCAL_RUN_SCENES';
export const REMOVE_ACTIVE_CLOUD_RUN_SCENES = 'REMOVE_ACTIVE_CLOUD_RUN_SCENES';
export const SET_FAILED_LOCAL_RUN_SCENES = 'SET_FAILED_LOCAL_RUN_SCENES';
export const SET_FAILED_CLOUD_RUN_SCENES = 'SET_FAILED_CLOUD_RUN_SCENES';
