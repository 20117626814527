import React from 'react';

export const IcControlInfo32 = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.5 28.9C17 29 16.5 29 16 29C15.5 29 15 29 14.5 28.9V25.9V20V19C13.4 19 12.5 18.1 12.5 17H14.5H15.5H17.5V19V25.9V28.9ZM16 3C8.8 3 3 8.8 3 16C3 21.6 6.5 26.4 11.5 28.2V24.9C8.2 23.3 6 19.9 6 16C6 10.5 10.5 6 16 6C21.5 6 26 10.5 26 16C26 19.9 23.8 23.3 20.5 24.9V28.2C25.5 26.4 29 21.6 29 16C29 8.8 23.2 3 16 3ZM16 15C17.1 15 18 14.1 18 13C18 11.9 17.1 11 16 11C14.9 11 14 11.9 14 13C14 14.1 14.9 15 16 15Z"
                fill="#FE5C15"
            />
        </svg>
    );
};
