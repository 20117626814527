import wsm from '../helpers/wsm';
import at from '../constants/ActionTypes/MeshBot';
import { bugsnagNotify, bugsnagNotifyWrapper } from '../containers/ErrorBoundary/utils';
import GenericActions from './GenericActions';
import { EzloActions } from './index';
import { toast, TOAST_TYPE } from '../components/Toast';
import { TOAST_MESSAGE_ON_ERROR } from '../constants/toasts';
// import { t } from '../helpers/language';
import { EZLOGIC_TITLE_CONTROLLER } from '../constants/language_tokens';

const actions = {
    /**
     * Function that returns redux thunk that makes a websocket request(hub.scenes.expressions.list) to get expressions.
     * @see {@link https://log.ezlo.com/new/hub/scenes/local_scenes_api/#hubscenesexpressionslist}
     * @param {string} serial - controller serial
     * @returns {function} redux thunk
     * @example
     * dispatch(getExpressions('925639'))
     */
    getExpressions: (serial) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.scenes.expressions.list',
                { showCode: true },
                (result) => {
                    resolve(result);
                },
                (error) => {
                    bugsnagNotifyWrapper(error, { type: at.GET_EXPRESSION_LIST.rejected, serial });
                    reject(error);
                },
            );
        }),
    /**
     * Function that returns redux thunk that makes a websocket request to get expressions and saves them to redux storage.
     * @param {string} serial - controller serial
     * @returns {function} redux thunk
     * @example
     * dispatch(updateExpressions('925639'))
     */
    updateExpressions: (serial, t) => async (dispatch) => {
        if (!serial) {
            return;
        }

        dispatch(GenericActions.setLineLoading(true));
        const response = await dispatch(actions.getExpressions(serial));
        if (response.expressions) {
            await dispatch(EzloActions.updateEzloExpressions(serial, response.expressions));
        } else {
            toast(`${t(TOAST_MESSAGE_ON_ERROR)}. ${t(EZLOGIC_TITLE_CONTROLLER)}: ${serial}`, {
                type: TOAST_TYPE.ERROR,
            });
        }
        await dispatch(GenericActions.setLineLoading(false));
    },

    deleteExpression: (serial, params, rejectCb, t) => (dispatch) => {
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.scenes.expressions.delete',
                { ...params },
                () => {
                    dispatch(actions.updateExpressions(serial, t));
                    dispatch({ type: at.DELETE_EXPRESSION.success });
                },
                (error) => {
                    bugsnagNotify(error, { type: at.DELETE_EXPRESSION.rejected, serial, params });
                    rejectCb(error);
                    reject(error);
                    dispatch({ type: at.DELETE_EXPRESSION.rejected });
                },
            );
        });
    },

    createExpression: (serial, params, rejectCb, t) => (dispatch) => {
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.scenes.expressions.set',
                { ...params },
                () => {
                    dispatch(actions.updateExpressions(serial, t));
                    dispatch({ type: at.CREATE_EXPRESSION.success });
                },
                (error) => {
                    bugsnagNotify(error, { type: at.CREATE_EXPRESSION.rejected, serial, params });
                    rejectCb(error);
                    reject(error);
                    dispatch({ type: at.CREATE_EXPRESSION.rejected });
                },
            );
        });
    },

    subscribeAddedExpression: (serial, cb) => () => {
        wsm.subscribe(serial, 'hub.expression.added', cb);
    },

    unsubscribeAddedExpression: (serial) => () => {
        wsm.unsubscribe(serial, 'hub.expression.added');
    },

    subscribeDeletedExpression: (serial, cb) => () => {
        wsm.subscribe(serial, 'hub.expression.deleted', cb);
    },

    unsubscribeDeletedExpression: (serial) => () => {
        wsm.unsubscribe(serial, 'hub.expression.deleted');
    },

    subscribeChangedExpression: (serial, cb) => () => {
        wsm.subscribe(serial, 'hub.expression.changed', cb);
    },

    unsubscribeChangedExpression: (serial) => () => {
        wsm.unsubscribe(serial, 'hub.expression.changed');
    },
};

export default actions;
