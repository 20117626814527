import { axiosCloudConfig } from '../../../../../../../../api/config';
import hash from '../../../../../../../../constants/uniqueHash';
import {
    buildGetAuthLinkRequestBody,
    buildIntegrationSetupRequestParams,
    buildIntegrationsPreviewsListRequestBody,
} from '../../../../../../EzloParjectsSettings/utils';

export const generateEnrollToPAASAuthRequestParams = (PAASAuthUuid, authType, authParjectId, token) => {
    return {
        capability: 'api',
        uuid: PAASAuthUuid,
        command: 'call',
        parameters: {
            name: 'storeToken',
            params: {
                authenticationType: authType,
                parjectGeneratedId: authParjectId,
                token,
            },
        },
    };
};

export const generateEnrollToPAASApikeyRequestParams = (PAASAuthUuid, authType, authParjectId) => {
    return {
        capability: 'api',
        uuid: PAASAuthUuid,
        command: 'call',
        parameters: {
            name: 'getUrl',
            params: {
                authenticationType: authType,
                parjectGeneratedId: authParjectId,
            },
        },
    };
};

// TODO devide to defferent functions
export const buildAuthUrlConfigRequestParams = (PAASAuthUuid, authType, authParjectId, token) => {
    let enrollToPAASAuthRequestParams;

    if (token) {
        enrollToPAASAuthRequestParams = generateEnrollToPAASAuthRequestParams(
            PAASAuthUuid,
            authType,
            authParjectId,
            token,
        );
    } else {
        enrollToPAASAuthRequestParams = generateEnrollToPAASApikeyRequestParams(PAASAuthUuid, authType, authParjectId);
    }

    const requestBody = buildGetAuthLinkRequestBody(enrollToPAASAuthRequestParams);

    return {
        method: 'post',
        ...axiosCloudConfig(),
        body: JSON.stringify(requestBody),
    };
};

export const getIntegrationsPreviewRequestParams = (uuid) => {
    const params = {
        call: 'integrations_preview',
        version: '1',
        params: {
            uuids: [uuid],
        },
    };

    return {
        method: 'post',
        ...axiosCloudConfig(),
        body: JSON.stringify(params),
    };
};

export const getAllIntegrationsPreviewRequestParams = () => {
    return {
        method: 'post',
        ...axiosCloudConfig(),
        body: JSON.stringify(buildIntegrationsPreviewsListRequestBody()),
    };
};

export const getPaasAuthEnrollRequestParams = (paasAuthUuid) => {
    const params = {
        uuid: paasAuthUuid,
        config_entity: {},
        info: {
            action: 'enroll',
            integration: paasAuthUuid,
        },
    };

    return {
        method: 'post',
        ...axiosCloudConfig(),
        body: JSON.stringify(buildIntegrationSetupRequestParams(params)),
    };
};

export const getEnumListMethodRequestBody = (uuid, method) => {
    return {
        call: 'abstract_command',
        id: hash(),
        version: '1',
        params: {
            uuid,
            capability: 'api',
            command: 'call',
            parameters: {
                name: method,
                params: {},
            },
        },
    };
};

export const getEnumListMethodRequestBodyForTrigger = (uuid, method) => {
    return {
        call: 'abstract_command',
        id: uuid,
        version: '1',
        params: {
            id: uuid,
            uuid: uuid,
            type: 'command',
            capability: 'api',
            command: 'call',
            parameters: {
                name: method,
                params: {},
                event_name: `/${method}`,
                custom: {
                    id: '',
                    label: '',
                },
            },
        },
    };
};
