import { X_AUTH_TYPE } from '../paas-constants';

export const getAuthUrl = (root) => {
    if (root.error_text) {
        return;
    }

    return root.body.data.data;
};

export const extractCallDefinitionListWithCallBack = (integrationPreviewResult) => {
    return (
        integrationPreviewResult?.call_definition?.list.reduce((acc, item) => {
            if (item.hasCallBack) {
                acc.push(item.name);
            }

            return acc;
        }, []) ?? []
    );
};

export const extractSubscriptionDefinitionListWithCallBack = (integrationPreviewResult) => {
    return (
        integrationPreviewResult?.subscribe_definition.list.reduce((acc, item) => {
            if (item.hasCallBack) {
                acc.push(item.name);
            }

            return acc;
        }, []) ?? []
    );
};

export const getNucalTriggerMethods = (integrationPreviewResult) => {
    const methods = Object.keys(integrationPreviewResult?.nucal_parject_definition.list.parjectJson.paths ?? []);
    const subscriptionDefinitionListWithCallBack = extractSubscriptionDefinitionListWithCallBack(
        integrationPreviewResult,
        methods,
    );

    return methods.filter((method) => subscriptionDefinitionListWithCallBack.includes(method.replace('/', '')));
};

export const getNucalMethods = (integrationPreviewResult) => {
    const callDefinitionListWithCallBack = extractCallDefinitionListWithCallBack(integrationPreviewResult);
    const methods = Object.keys(integrationPreviewResult?.nucal_parject_definition?.list?.parjectJson?.paths ?? []);

    return methods?.filter((method) => !callDefinitionListWithCallBack.includes(method.replace('/', '')));
};

export const getPaths = (integrationPreviewResult) => {
    return integrationPreviewResult?.nucal_parject_definition.list.parjectJson.paths;
};

export const getNucalParjectDefinition = (integrationPreviewResult) => {
    return integrationPreviewResult?.nucal_parject_definition;
};

export const getNucalSchemaKey = (integrationPreviewResult, methodName) => {
    const nucalParjectDefinition = getNucalParjectDefinition(integrationPreviewResult);

    if (!nucalParjectDefinition || !methodName) {
        return;
    }
    methodName = '/' + methodName;
    const { schema } =
        nucalParjectDefinition?.list?.parjectJson?.paths[methodName]?.post?.requestBody?.content['application/json'];

    const splittedSchema = schema['$ref']?.split('/');
    const schemaKey = splittedSchema[splittedSchema?.length - 1];

    return schemaKey;
};

export const getNucalComponentBySchemaKey = (integrationPreviewResult, schemaKey) => {
    const nucalParjectDefinition = getNucalParjectDefinition(integrationPreviewResult);

    if (!nucalParjectDefinition) {
        return;
    }

    return nucalParjectDefinition.list.parjectJson.components.schemas[schemaKey];
};

export const deleteAbstractRequestParams = (parjectUuid, abstractUuid) => {
    return {
        call: 'integrations_setup',
        params: {
            uuid: parjectUuid,
            info: {
                action: 'delete',
                uuid_index: abstractUuid,
            },
        },
    };
};

export const getParjectGeneratedId = (integrationPreviewResult) => {
    const nucal_parject_definition = integrationPreviewResult?.nucal_parject_definition;
    const list = nucal_parject_definition?.list;
    const parjectGeneratedId = list?.parjectGeneratedId;

    return parjectGeneratedId;
};

export const getAuthenticationType = (integrationPreviewResult) => {
    const nucal_parject_definition = integrationPreviewResult?.nucal_parject_definition;
    const list = nucal_parject_definition?.list;
    const parjectJson = list?.parjectJson;
    const info = parjectJson?.info;
    const authenticationType = info?.[X_AUTH_TYPE];

    return authenticationType;
};

export const extractIntegrationUUID = (abstract) => {
    const details = abstract?.details;

    return details?.integration_uuid;
};
