export default {
    SET_INSTALLATIONS_LIST: {
        success: 'SET_INSTALLATIONS_LIST/success',
        rejected: 'SET_INSTALLATIONS_LIST/rejected',
    },
    SET_MAILING_BILLING_ADDRESSES: {
        success: 'SET_MAILING_BILLING_ADDRESSES/success',
        rejected: 'SET_MAILING_BILLING_ADDRESSES/rejected',
    },
    UPDATE_INSTALLATION_ADDRESSES: {
        rejected: 'UPDATE_INSTALLATION_ADDRESSES/rejected',
    },
    UPDATE_INSTALLATION_ADDRESS: {
        rejected: 'UPDATE_INSTALLATION_ADDRESS/rejected',
    },
    UPDATE_MAILING_BILLING_ADDRESSES: {
        rejected: 'UPDATE_MAILING_BILLING_ADDRESSES/rejected',
    },
    SET_COUNTRIES_LIST: {
        success: 'SET_COUNTRIES_LIST/success',
        rejected: 'SET_COUNTRIES_LIST/rejected',
    },
    SET_REGIONS_LIST: {
        rejected: 'SET_REGIONS_LIST/rejected',
    },
    SET_CITIES_LIST: {
        rejected: 'SET_CITIES_LIST/rejected',
    },
};
