export const MAIN_CONTENT_VIEW = {
    CREATE: 'CREATE',
    RESTORE: 'RESTORE',
    NOT_SUPPORTED: 'NOT_SUPPORTED',
};

export const CREATE_BACKUP_STEPS = {
    CREATE_BACKUP: 'CREATE_BACKUP',
    IN_PROGRESS: 'IN_PROGRESS',
    BACKUP_COMPLETED: 'BACKUP_COMPLETED',
    BACKUP_ERROR: 'BACKUP_ERROR',
};

export const RESTORE_BACKUP_STEPS = {
    RESTORE_BACKUP: 'RESTORE_BACKUP',
    CONFIRM_RESTORING: 'CONFIRM_RESTORING',
    IN_PROGRESS: 'IN_PROGRESS',
    BACKUP_RESTORED: 'BACKUP_RESTORED',
    BACKUP_ERROR: 'BACKUP_ERROR',
};

export const BACKUP_FILE_TYPE = 'archive_controller_backup';

export const STORAGE_LIST_URL = 'https://api-cloud.ezlo.com/v1/request';
export const CLOUD_EZLO_URL = 'https://cloud.ezlo.com/mca-router/token/exchange/legacy-to-cloud/';

export const BACKUP_SUPPORTED_ARCHITECTURES = ['armv7l']; // architecture types
export const INTEGRATIONS_SUPPORTED_ARCHITECTURES = ['armv7l']; // for now, same as for backups

export const BACKUP_FILES_NUMBER_PER_PAGE = 100; // todo: need to implement pagination, depends on storage_list v2.
export const PAGE_NUMBER = 1;
export const CALL_VERSION = '2';
export const BACKUPS_DEFAULT_UNIT_AMOUNT = 14;
export const BACKUPS_DEFAULT_UNIT_PERIOD = 'days';
export const REQUEST_METHOD_POST = 'post';
export const STORAGE_LIST_CALL = 'storage_list';
export const CONTROLLER_BACKUPS_BUCKET = 'controller_backups';
export const DEFAULT_BUCKET = 'default';
